






































import { Component, Vue } from "vue-property-decorator";
import { Form as elForm } from "element-ui";
import { PostLogin } from "@/request/account";
import { clearFilter } from "@/utils/base";
@Component({})
export default class Login extends Vue {
  private nameType: any = "password";
  private window: any = window;
  private info: any = {
    用户名: "",
    密码: "",
  };
  private rules: any = {
    用户名: [{ required: true, message: "账户名必须填写", trigger: "blur" }],
    密码: [{ required: true, message: "密码必须填写", trigger: "blur" }],
  };
  private get configuration() {
    return this.$store.state.configuration;
  }
  private nameInput() {
    this.nameType = "text";
  }
  private login() {
    (this.$refs.loginForm as elForm).validate((isValid: boolean) => {
      const Base64 = require("js-base64").Base64;
      if (isValid) {
        const params: any = {
          account: this.info["用户名"],
          密码: this.info["密码"],
        };
        const loading = this.$loading({
          lock: true,
          text: "加载中……",
          spinner: "el-icon-loading",
          customClass: "loadClass",
          background: "rgba(255, 255, 255, 0.7)",
        });
        PostLogin(this, params, loading)
          .then((res) => {
            loading.close();
            this.$store.commit("updateToken", res.token);
            this.$store.commit("updateUser", res.user);
            this.$store.commit("updatePatient", null);
            this.$store.commit("updatePatientFileFilter", {
              firstTab: "健康画像", //一级导航
              secondTab: "", // 二级导航
              thirdTab: "", // 三级导航,
              fourTab: "", // 四级导航,
              fourOpenId: "", // 三级导航是否打开字段
              isHideLeft: false, //是否隐藏左边患者列表
            });
            clearFilter();
            if (this.configuration.leftNav[0].path) {
              this.$router.push(this.configuration.leftNav[0].path);
            } else {
              this.$router.push(this.configuration.leftNav[0].children[0].path);
            }
          })
          .catch((error: any) => {
            loading.close();
            if (typeof error === "string") {
              this.$message.error(error);
            }
          });
      }
    });
  }
}
